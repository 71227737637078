import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import LocalStorageService from "@/core/services/localstorage.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_MSC_DATA_QUALITY_ISSUES } from "./graphql/data_quality_issues.queries";
import {
  CLOUD_PROVIDER_ENUM,
  COUNTRY_ENUM,
  MSC_SCALE_ENUM
} from "@/core/constants";
import { GET_MSC_CUSTOMERS } from "../customers/graphql/customers.queries";
//#region Vee Validation
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is required"
});
//#endregion

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      singleExpand: true,
      isLoading: false,
      skipQuery: true,
      search: "",
      filterFormValid: false,
      cloudProviderList: [
        { name: CLOUD_PROVIDER_ENUM.AWS },
        { name: CLOUD_PROVIDER_ENUM.AZURE }
      ],
      countryList: Object.entries(COUNTRY_ENUM).map(([name]) => ({
        name
      })),
      selectedCloudProvider: null,
      selectedCountry: null,
      customerList: [],
      dataQualityIssues: [],
      selectedCustomer: null,
      tableHeaders: [
        { text: "Message", value: "message" },
        { text: "", value: "data-table-expand", align: "right" }
      ]
    };
  },
  apollo: {
    customers: {
      client: "connectClient",
      query: GET_MSC_CUSTOMERS,
      variables() {
        return this.getCustomersQueryVariable();
      },
      update(data) {
        let customers = data.finance.managedServicesConnector.customers;
        if (!customers.result) {
          NotificationService.error(
            `${customers.metadata.message} (${customers.metadata.statusCode})`
          );
          return [];
        } else {
          this.customerList = customers.result;
        }

        LocalStorageService.setItem(
          this.getCustomerLocalStorageKey(),
          this.customerList
        );
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.customers.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      }
    },
    dataQualityIssues: {
      client: "connectClient",
      query: GET_MSC_DATA_QUALITY_ISSUES,
      variables() {
        return this.getMscDataQualityIssuesQueryVariable();
      },
      update(data) {
        let issues = data.finance.managedServicesConnector.dataQualityIssues;
        if (!issues.result) {
          NotificationService.error(
            `${issues.metadata.message} (${issues.metadata.statusCode})`
          );
          return [];
        } else {
          return issues.result;
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.dataQualityIssues.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      },
      notifyOnNetworkStatusChange: true,
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  methods: {
    getCustomerLocalStorageKey() {
      return `${this.selectedCloudProvider.name}.${this.selectedCountry.name}.${MSC_SCALE_ENUM.CUSTOMER}`;
    },
    customFilter(item, search, filter) {
      search = search.toString().toLowerCase();
      return Object.values(filter).some(v => {
        if (typeof v === "string")
          return v && v.toString().toLowerCase().includes(search);
        else {
          return Object.values(v).some(sub => {
            return Object.keys(sub).some(function (key) {
              return sub[key].toString().toLowerCase().includes(search);
            });
          });
        }
      });
    },
    loadMscDataQualityIssues() {
      this.$apollo.queries.dataQualityIssues.skip = false;
      this.$apollo.queries.dataQualityIssues.refetch();
    },
    getCustomersQueryVariable() {
      return {
        filters: {
          addDetails: {
            value: [false]
          },
          cloudProvider: {
            value: [this.selectedCloudProvider.name]
          },
          country: {
            value: [this.selectedCountry.name]
          }
        }
      };
    },
    getMscDataQualityIssuesQueryVariable() {
      return {
        filters: {
          cloudProvider: {
            value: [this.selectedCloudProvider.name]
          },
          country: {
            value: [this.selectedCountry.name]
          },
          customerAccountNumber: {
            value: [this.selectedCustomer.accountNumber]
          }
        }
      };
    },
    cloudProviderSelectionChanged() {
      this.selectedCountry = null;
      this.customerList = [];
      this.selectedCustomer = null;
      this.dataQualityIssues = [];
    },
    countrySelectionChanged() {
      if (this.selectedCloudProvider && this.selectedCountry) {
        this.customerList = [];
        this.selectedCustomer = null;
        this.dataQualityIssues = [];

        let blacklistedCustomers = LocalStorageService.getItem(
          this.getCustomerLocalStorageKey()
        );

        if (blacklistedCustomers) {
          this.customerList = blacklistedCustomers;
        }
        this.$apollo.queries.customers.skip = false;
        this.$apollo.queries.customers.refetch();
      }
    },
    customerSelectionChanged() {
      this.dataQualityIssues = [];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "Navigation Menu Group",
        route: "/group/navigation-menu"
      },
      {
        title: "MSC Data Quality Issues"
      }
    ]);
  }
};
