const ID_TOKEN_KEY = "id_token";
const ACCOUNT_ID_KEY = "account_id";
const ACCOUNT_INFO_KEY = "account_info";

const LocalStorageService = {
  getToken() {
    const token = window.localStorage.getItem(ID_TOKEN_KEY);
    if (token) {
      try {
        return JSON.parse(token);
      } catch (e) {
        window.localStorage.removeItem(ID_TOKEN_KEY);
      }
    }
    return null;
  },
  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token));
  },
  clear() {
    window.localStorage.clear();
  },
  setActiveAccount(account) {
    window.localStorage.removeItem(ACCOUNT_INFO_KEY);
    window.localStorage.setItem(ACCOUNT_ID_KEY, account);
  },
  getActiveAccount() {
    return window.localStorage.getItem(ACCOUNT_ID_KEY);
  },
  saveAccountInfo(account) {
    window.localStorage.setItem(ACCOUNT_INFO_KEY, JSON.stringify(account));
  },
  getAccountInfo() {
    const account = window.localStorage.getItem(ACCOUNT_INFO_KEY);
    if (account) {
      try {
        return JSON.parse(account);
      } catch (e) {
        window.localStorage.removeItem(ACCOUNT_INFO_KEY);
      }
    }
    return null;
  },
  setItem(key, item) {
    window.localStorage.setItem(key, JSON.stringify(item));
  },
  getItem(key) {
    const item = window.localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        window.localStorage.removeItem(key);
      }
    }
    return null;
  }
};

export default LocalStorageService;
