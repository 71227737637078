import gql from "graphql-tag";

const GET_MSC_DATA_QUALITY_ISSUES = gql`
  query DataQualityIssueList(
    $filters: ManagedServicesConnectorDataQualityIssuesInput!
  ) {
    finance {
      managedServicesConnector {
        dataQualityIssues(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result {
            message
            resources {
              firstSeen
              issueType
              lastSeen
              resourceId
            }
            solution
          }
        }
      }
    }
  }
`;

export { GET_MSC_DATA_QUALITY_ISSUES };
